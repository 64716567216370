<script>
import { MERCHANT_ID } from '@/constants/names';
import FlatPickr from 'vue-flatpickr-component';
import VueApexCharts from 'vue-apexcharts';
import api from '@/services/api';
import PageTitle from '@/components/PageTitle.vue';
import ICountUp from 'vue-countup-v2';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { BCard } from 'bootstrap-vue';
import GroupsSelect from '@/views/tournament/statistics/elements/GroupsSelect.vue';
import TasksSelect from '@/views/tournament/tournament-tasks/TasksSelect.vue';
import vSelect from 'vue-select';
import { checkLocales } from '@/util/lang';
import { formatYMD, getFirstDateOfMonth, getLastDateOfMonth } from '@/util/date';

export default {
    name: 'AppTournamentStatistics',
    components: {
        vSelect,
        GroupsSelect,
        TasksSelect,
        BCard,
        ProgressLinear,
        ICountUp,
        PageTitle,
        FlatPickr,
        apexchart: VueApexCharts
    },
    data() {
        return {
            merchant_id: parseInt(MERCHANT_ID),
            activeUsersTotal: null,
            attendanceTotal: null,
            tournamentList: null,
            tournament_id: null,
            group_id: null,
            task_id: null,
            activeUserStatisticsChart: {
                series: [],
                chartOptions: {
                    chart: {
                        id: 'users-statistics-chart',
                        type: 'bar',
                        height: 350,
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    responsive: [
                        {
                            breakpoint: 992,
                            options: {
                                chart: {
                                    height: 700
                                },
                                plotOptions: {
                                    bar: {
                                        horizontal: true
                                    }
                                }
                            }
                        }
                    ],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 4,
                            hideZeroBarsWhenGrouped: false,
                            dataLabels: {
                                total: {
                                    enabled: true,
                                    style: {
                                        color: '#28c76f',
                                        fontSize: '13px',
                                        fontWeight: 900
                                    }
                                }
                            }
                        }
                    },
                    xaxis: {
                        categories: []
                    },
                    legend: {
                        position: 'bottom',
                        offsetY: 6
                    },
                    fill: {
                        opacity: 1
                    }
                }
            },
            // tasksStatisticsChart: {
            //     series: [],
            //     chartOptions: {
            //         chart: {
            //             id: 'tasks-statistics-chart',
            //             type: 'bar',
            //             height: 350,
            //             toolbar: {
            //                 show: false
            //             },
            //             zoom: {
            //                 enabled: false
            //             }
            //         },
            //         responsive: [
            //             {
            //                 breakpoint: 992,
            //                 options: {
            //                     chart: {
            //                         height: 700
            //                     },
            //                     plotOptions: {
            //                         bar: {
            //                             horizontal: true
            //                         }
            //                     }
            //                 }
            //             }
            //         ],
            //         plotOptions: {
            //             bar: {
            //                 horizontal: false,
            //                 borderRadius: 4,
            //                 hideZeroBarsWhenGrouped: false,
            //                 dataLabels: {
            //                     total: {
            //                         enabled: true,
            //                         style: {
            //                             color: '#28c76f',
            //                             fontSize: '13px',
            //                             fontWeight: 900
            //                         }
            //                     }
            //                 }
            //             }
            //         },
            //         xaxis: {
            //             categories: []
            //         },
            //         legend: {
            //             position: 'bottom',
            //             offsetY: 6
            //         },
            //         fill: {
            //             opacity: 1
            //         }
            //     }
            // },
            tasksStatisticsByTaskChart: {
                series: [],
                chartOptions: {
                    colors: [
                        '#006f62',
                        '#a7204c',
                        '#f87002',
                        '#e17dab',
                        '#0097df',
                        '#739373',
                        '#002296',
                        '#b03966',
                        '#057f5f'
                    ],
                    chart: {
                        id: 'tasks-statistics-by-task-chart',
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    responsive: [
                        {
                            breakpoint: 992,
                            options: {
                                chart: {
                                    height: 900
                                },
                                plotOptions: {
                                    bar: {
                                        horizontal: true
                                    }
                                }
                            }
                        }
                    ],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 4,
                            hideZeroBarsWhenGrouped: false,
                            dataLabels: {
                                total: {
                                    enabled: true,
                                    style: {
                                        color: '#28c76f',
                                        fontSize: '13px',
                                        fontWeight: 900
                                    }
                                }
                            }
                        }
                    },
                    xaxis: {
                        categories: []
                    },
                    legend: {
                        position: 'bottom',
                        offsetY: 6
                    },
                    fill: {
                        opacity: 1
                    }
                }
            },
            windowWidth: window.innerWidth,
            countUpOptions: {
                useEasing: true,
                useGrouping: true,
                separator: ' ',
                decimal: '.',
                prefix: '',
                suffix: ''
            },
            requestPending: false,
            dateRange: []
        };
    },

    async created() {
        this.requestPending = true;
        await Promise.allSettled([this.getActiveUsersTotal(), this.getAttendanceTotal()]);
        await this.getTournamentList();
        this.requestPending = false;
        await this.fixDateRange();
    },

    watch: {
        tournament_id(value) {
            if (!value) {
                this.group_id = null;
            }
        }
    },

    methods: {
        checkLocales,
        async allStatisticsApiCall() {
            await Promise.allSettled([
                this.getActiveUsersStatistics(this.dateRange[0], this.dateRange[1]),
                this.getTaskStatisticsByTasks(this.dateRange[0], this.dateRange[1])
            ]);
        },

        async fixDateRange() {
            const date = new Date();
            const year = date.getUTCFullYear(); // Get the full year (4 digits)
            const beforeMonth = String(date.getMonth()).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            const currentMonth = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if necessary
            this.dateRange = [];
            this.dateRange[0] = `${year}-${beforeMonth}-${day}`;
            this.dateRange[1] = `${year}-${currentMonth}-${day}`;
            await this.allStatisticsApiCall();
        },

        async changeRange(dates) {
            await this.getActiveUsersStatistics(formatYMD(dates[0]), formatYMD(dates[1]));
            await this.getTaskStatisticsByTasks(formatYMD(dates[0]), formatYMD(dates[1]));
        },

        changeChartType() {
            this.activeUserStatisticsChart.chartOptions.plotOptions.bar.horizontal =
                this.windowWidth < 576;
        },

        // async getDatesForParam() {
        //     const date = new Date();
        //     const currentYear = date.getUTCFullYear(); // Get the full year (4 digits)
        //     const currentMonth = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
        //     const currentDay = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if necessary
        //     this.dateRange[0] = `${currentYear}-${currentMonth}-${currentDay}`;
        //     this.dateRange[1] = `${currentYear}-${currentMonth}-${currentDay}`;
        // },

        // FETCH TOURNAMENT LIST
        async getTournamentList() {
            const body = {
                page: 1,
                limit: 30
            };
            const { data } = await api.tournament.fetchAllTournaments(body);
            this.tournamentList = data.result;
        },

        // FETCH ACTIVE USERS
        async getActiveUsersTotal() {
            const body = {
                merchant_id: this.merchant_id
            };
            const { data } = await api.tournament.activeUserTotal(body);
            this.activeUsersTotal = data.result;
        },

        // FETCH ATTENDANCE TOTAL
        async getAttendanceTotal() {
            const body = {
                merchant_id: this.merchant_id
            };
            const { data } = await api.coin.getTournamentPolicyTotal(body);
            this.attendanceTotal = data.result;
        },

        // FETCH ACTIVE USERS STATISTICS
        async getActiveUsersStatistics(fromDate, toDate) {
            const body = {
                merchant_id: this.merchant_id,
                from_date: fromDate,
                to_date: toDate,
                group_id: this.group_id?.id,
                task_id: this.task_id?.id
            };
            if (this.tournament_id?.id) {
                body.from_date = getFirstDateOfMonth(this.tournament_id.start_date);
                body.to_date = getLastDateOfMonth(this.tournament_id.start_date);
            }
            this.activeUserStatisticsChart.chartOptions.xaxis.categories.length = 0;
            const { data } = await api.tournament.activeUserStatistics(body);
            const response = data.result;

            response.forEach((childItem) => {
                const [d, m] = childItem.date.split('-');
                this.activeUserStatisticsChart.chartOptions.xaxis.categories.push(`${d}.${m}`);
            });

            this.activeUserStatisticsChart.series = [
                {
                    name: '',
                    data: []
                }
            ];

            response.forEach((item) => {
                this.activeUserStatisticsChart.series[0].data.push(item.count);
            });
        },

        // FETCH TASKS STATISTICS
        // async getTasksStatistics({ fromDate, toDate }) {
        //     const body = {
        //         merchant_id: this.merchant_id
        //     };
        //     this.tasksStatisticsChart.chartOptions.xaxis.categories.length = 0;
        //     const { data } = await api.tournament.fullTaskStatistics(body, fromDate, toDate);
        //     const response = data.result;
        //
        //     response.forEach((childItem) => {
        //         const [d, m] = childItem.date.split('-');
        //         this.tasksStatisticsChart.chartOptions.xaxis.categories.push(`${d}.${m}`);
        //     });
        //
        //     this.tasksStatisticsChart.series = [
        //         {
        //             name: '',
        //             data: []
        //         }
        //     ];
        //
        //     response.forEach((item) => {
        //         this.tasksStatisticsChart.series[0].data.push(item.count);
        //     });
        // },

        // FETCH TASKS STATISTICS BY TASK
        async getTaskStatisticsByTasks(fromDate, toDate) {
            const body = {
                merchant_id: this.merchant_id,
                from_date: fromDate,
                to_date: toDate,
                group_id: this.group_id?.id
            };

            if (this.tournament_id?.id) {
                body.from_date = getFirstDateOfMonth(this.tournament_id.start_date);
                body.to_date = getLastDateOfMonth(this.tournament_id.start_date);
            }

            this.tasksStatisticsByTaskChart.chartOptions.xaxis.categories.length = 0;
            const { data } = await api.tournament.fullTaskStatisticsByTask(body);
            const response = data.result;

            response[0].statistics.forEach((item) => {
                const [d, m] = item.date.split('-');
                this.tasksStatisticsByTaskChart.chartOptions.xaxis.categories.push(`${d}.${m}`);
            });

            this.tasksStatisticsByTaskChart.series = response.map((item) => {
                return {
                    name: checkLocales(item.award.name),
                    data: item.statistics.map((childItem) => {
                        return childItem.count;
                    })
                };
            });
        },

        resetFilter() {
            this.tournament_id = null;
            this.group_id = null;
            this.task_id = null;
            this.allStatisticsApiCall();
        }
    }
};
</script>

<template>
    <section class="tournament-statistics">
        <page-title>
            <template #title> {{ $t('tournament.statistics') }}</template>
        </page-title>

        <progress-linear v-if="requestPending" />

        <div v-else class="row">
            <div class="col-12 col-md-6">
                <div class="card total-users d-flex flex-row align-items-center p-2">
                    <b-avatar variant="light-success" size="lg" class="mr-1">
                        <feather-icon icon="UsersIcon" size="28" />
                    </b-avatar>
                    <div>
                        <p>{{ $t('tournament.active_users_statistics') }}</p>
                        <span v-if="activeUsersTotal">
                            <i-count-up
                                :delay="100"
                                :end-val="activeUsersTotal"
                                :options="countUpOptions"
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card total-users d-flex flex-row align-items-center p-2">
                    <b-avatar variant="light-warning" size="lg" class="mr-1">
                        <feather-icon icon="DollarSignIcon" size="28" />
                    </b-avatar>
                    <div>
                        <p>{{ $t('tournament.total_attendance_statistics') }}</p>
                        <span v-if="attendanceTotal">
                            <i-count-up
                                :delay="100"
                                :end-val="attendanceTotal"
                                :options="countUpOptions"
                            />
                        </span>
                    </div>
                </div>
            </div>

            <!--      FILTER      -->
            <div class="col-12">
                <b-card>
                    <div class="d-flex align-items-end flex-wrap col-12 px-0">
                        <!--     TOURNAMENT SELECT     -->
                        <div class="col-12 col-sm-6 col-lg-3 mb-1 mb-md-0">
                            <!--                            <tournaments-select v-model="tournament_id" :disabled="false" />-->
                            <label>{{ $t('tournament.tournaments_title') }}</label>

                            <v-select v-model="tournament_id" :options="tournamentList">
                                <template #option="{ name }">
                                    <span>{{ checkLocales(name) }}</span>
                                </template>

                                <template #selected-option="{ name }">
                                    {{ checkLocales(name) }}
                                </template>
                            </v-select>
                        </div>

                        <!--     GROUP SELECT     -->
                        <div class="col-12 col-sm-6 col-lg-2 mb-1 mb-md-0">
                            <groups-select v-model="group_id" :tournament-id="tournament_id" />
                        </div>

                        <!--     TASK SELECT     -->
                        <div class="col-12 col-md-10 col-lg-5 mb-1 mb-md-0">
                            <tasks-select v-model="task_id" />
                        </div>

                        <!--     X BUTTON     -->
                        <div
                            class="d-flex col-12 col-sm-4 col-md-2 col-lg-2 offset-sm-4 offset-md-0"
                        >
                            <b-button
                                @click="resetFilter"
                                variant="outline-primary"
                                size="sm"
                                class="w-100 px-0 mr-1"
                            >
                                <feather-icon icon="RefreshCcwIcon" size="22" />
                            </b-button>
                            <b-button
                                @click="allStatisticsApiCall"
                                variant="outline-info"
                                size="sm"
                                class="w-100 px-0"
                            >
                                <feather-icon icon="SearchIcon" size="22" />
                            </b-button>
                        </div>
                    </div>
                </b-card>
            </div>

            <!--      STATISTICS      -->
            <div class="col-12">
                <div class="card p-2">
                    <div class="d-flex align-items-center justify-content-between px-2">
                        <h3 class="mb-1">{{ $t('titles.game_user_statistics') }}</h3>
                        <div
                            v-if="!tournament_id && !task_id"
                            class="d-flex align-items-center"
                            style="min-width: 250px"
                        >
                            <!--                            <feather-icon icon="CalendarIcon" size="16" />-->
                            <!--                            @on-change="changeInterval"-->
                            <flat-pickr
                                v-model="dateRange"
                                :config="{ mode: 'range' }"
                                class="form-control ml-1"
                                :placeholder="$t('choose.date')"
                                style="min-width: 200px"
                                @on-close="changeRange"
                            />
                            <b-button
                                @click="fixDateRange"
                                size="sm"
                                variant="outline-primary"
                                class="ml-1"
                            >
                                <feather-icon icon="RefreshCcwIcon" size="16" />
                            </b-button>
                        </div>
                    </div>
                    <div class="col-12">
                        <apexchart
                            id="users-statistics-chart"
                            ref="usersStatisticsChart"
                            height="400"
                            type="bar"
                            :options="activeUserStatisticsChart.chartOptions"
                            :series="activeUserStatisticsChart.series"
                        ></apexchart>
                    </div>
                    <!--                    <div class="col-12">-->
                    <!--                        <h3 class="mb-1 mt-2">{{ $t('tournament.task_statistics') }}</h3>-->
                    <!--                        <apexchart-->
                    <!--                            id="tasks-statistics-chart"-->
                    <!--                            ref="tasksStatisticsChart"-->
                    <!--                            height="400"-->
                    <!--                            type="bar"-->
                    <!--                            :options="tasksStatisticsChart.chartOptions"-->
                    <!--                            :series="tasksStatisticsChart.series"-->
                    <!--                        ></apexchart>-->
                    <!--                    </div>-->
                    <div class="col-12">
                        <h3 class="mb-1 mt-2">{{ $t('tournament.tasks_statistics') }}</h3>
                        <apexchart
                            id="tasks-statistics-by-task-chart"
                            ref="tasksStatisticsByTaskChart"
                            height="400"
                            type="bar"
                            :options="tasksStatisticsByTaskChart.chartOptions"
                            :series="tasksStatisticsByTaskChart.series"
                        ></apexchart>
                    </div>
                </div>
            </div>

            <!--            &lt;!&ndash;      TASK STATISTICS      &ndash;&gt;-->
            <!--            <div class="col-12">-->
            <!--                <div class="card p-2">-->
            <!--                    <div class="d-flex align-items-center justify-content-between">-->
            <!--                        <h3 class="mb-1">{{ $t('tournament.task_statistics') }}</h3>-->
            <!--                        <div class="d-flex align-items-center" style="min-width: 250px">-->
            <!--                            <feather-icon icon="CalendarIcon" size="16" />-->
            <!--                            &lt;!&ndash;                            @on-change="changeInterval"&ndash;&gt;-->
            <!--                            <flat-pickr-->
            <!--                                v-model="rangePicker"-->
            <!--                                :config="{ mode: 'range' }"-->
            <!--                                class="form-control ml-1"-->
            <!--                                :placeholder="$t('choose.date')"-->
            <!--                                style="min-width: 200px"-->
            <!--                            />-->
            <!--                            <b-button variant="outline-danger" class="p-0 ml-1">-->
            <!--                                <feather-icon icon="XIcon" size="16" />-->
            <!--                            </b-button>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <apexchart-->
            <!--                        id="tasks-statistics-chart"-->
            <!--                        ref="tasksStatisticsChart"-->
            <!--                        height="400"-->
            <!--                        type="bar"-->
            <!--                        :options="tasksStatisticsChart.chartOptions"-->
            <!--                        :series="tasksStatisticsChart.series"-->
            <!--                    ></apexchart>-->
            <!--                </div>-->
            <!--            </div>-->

            <!--            &lt;!&ndash;      TASK STATISTICS by TASK      &ndash;&gt;-->
            <!--            <div class="col-12">-->
            <!--                <div class="card p-2">-->
            <!--                    <div class="d-flex align-items-center justify-content-between">-->
            <!--                        <h3 class="mb-1">{{ $t('tournament.tasks_statistics') }}</h3>-->
            <!--                        <div class="d-flex align-items-center" style="min-width: 250px">-->
            <!--                            <feather-icon icon="CalendarIcon" size="16" />-->
            <!--                            &lt;!&ndash;                            @on-change="changeInterval"&ndash;&gt;-->
            <!--                            <flat-pickr-->
            <!--                                v-model="rangePicker"-->
            <!--                                :config="{ mode: 'range' }"-->
            <!--                                class="form-control ml-1"-->
            <!--                                :placeholder="$t('choose.date')"-->
            <!--                                style="min-width: 200px"-->
            <!--                            />-->
            <!--                            <b-button variant="outline-danger" class="p-0 ml-1">-->
            <!--                                <feather-icon icon="XIcon" size="16" />-->
            <!--                            </b-button>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <apexchart-->
            <!--                        id="tasks-statistics-by-task-chart"-->
            <!--                        ref="tasksStatisticsByTaskChart"-->
            <!--                        height="400"-->
            <!--                        :options="tasksStatisticsByTaskChart.chartOptions"-->
            <!--                        :series="tasksStatisticsByTaskChart.series"-->
            <!--                    ></apexchart>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
    </section>
</template>

<style scoped lang="scss">
.total-users {
    & p {
        font-size: 16px;
        margin-bottom: 4px;
    }

    & span {
        font-size: 18px;
        font-weight: 700;
    }
}

.badge span {
    margin-bottom: 4px;
}

.badge-parent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0.5rem;

    & span {
        padding-bottom: 4px;
    }

    & span.badge {
        padding-bottom: 0;
    }

    & span.text-nowrap {
        padding-top: 1rem;
    }
}

@media only screen and (max-width: 1100px) {
    .badge-parent {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 1rem;
        grid-row-gap: 0.5rem;
    }
}
</style>
